<template>
  <div 
    class="header" 
    :style="`position: ${headerPosition}; top: ${headerTop}px`"
    :class="{ 'header_focus': sfocus, 'header_light': (headerThemeLight && !modal), 'header_short': headerShort }"
  >
    <div class="uk-container uk-container-large">
      <div class="header__container">
        <div class="logo header__logo">
          <a href="/">
            <KangarooLogo />
          </a>
        </div>
        <div class="header__navigator">
          <div class="header-menu">
            <div class="header-menu__container main">
              <div
                  v-for="(item, i) in MainMenu"
                  :key="i"
                  class="header-menu__item width-md"
                  :class="{ 'active' : 
                    (
                      !$route.path.indexOf(item.href)
                      ||
                      (item.href === '/catalog' && !$route.path.indexOf('/product'))
                    )
                  }"
              >
                <a
                  :href="item.href"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>

            <div class="header-menu__container secondary">
              <div
                  v-for="(item, i) in SecondaryMenu"
                  :key="i"
                  class="header-menu__item"
                  :class="{ 'active' : !$route.path.indexOf(item.href) }"
              >
                <a
                  :href="item.href"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>

          <div class="header-search" v-if="!modal">
            <div class="header-search__button">
              <button
                  @click="search($event)"
                  class="btn-search"
                  aria-label="Поиск"
              >
                <div class="kng-i kng-i-search"></div>
              </button>
              <input
                  type="text"
                  ref="input-search"
                  class="input-search"
                  placeholder="Поиск на сайте"
                  v-model="textSearch"
                  @focus="sfocus = true"
                  @blur="sfocus = false"
                  @keypress.enter="search($event)"
              />
            </div>
          </div>

          <div class="header-overlay">
            <div class="header-overlay__btn">
              <button
                  @click="openModal()"
                  class="btn-menu"
                  aria-label="Поиск"
              >
                <div v-if="!modal" class="kng-i kng-i-menu"></div>
                <div v-else class="kng-i kng-i-close"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modal" class="header-modal">
      <div class="uk-container uk-container-large">
        <div class="header-modal__container">
          <div class="container-group">
            <div
                v-for="(item, i) in MainMenu"
                :key="i"
                class="container-item main"
                :class="{ 'active' : !$route.path.indexOf(item.href) }"
            >
              <a :href="item.href">
                {{ item.title }}
              </a>
            </div>
          </div>

          <div class="container-group secondary">
            <div
                v-for="(item, i) in SecondaryMenu"
                :key="i"
                class="container-item"
                :class="{ 'active' : !$route.path.indexOf(item.href) }"
            >
              <a
                :href="item.href"
              >
                {{ item.title }}
              </a>
            </div>
          </div>
        </div>
        <button 
          @click="callModal('marketplace')"
          class="button button-black"
        >
          Купить на маркетплейсах
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import MenuJson from '@/assets/data/menu.json';
import KangarooLogo from '@/assets/img/svg/kangaroo_logo.svg';

export default {
  name: 'header-component',
  components: {
    KangarooLogo
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      MainMenu: MenuJson.filter(el => el.type === 1),
      SecondaryMenu: MenuJson.filter(el => el.type === 2),
      sfocus: false,
      textSearch: '',
      modal: false
    }
  },
  computed: {
    headerThemeLight() {
      return this.$store.state.headerTheme == "light"
    },
    headerShort() {
      return this.$route.name == "product" && (this.isDevice !== 'mobile')
    },
    headerPosition() {
      return this.$store.state.headerPosition
    },
    headerTop() {
      return this.$store.state.headerTop
    },
  },
  methods: {
    openModal() {
      this.modal = !this.modal
      let body = document.getElementById("app").parentElement;
      if (this.modal) body.classList.add('modal-open');
      else body.classList.remove('modal-open');
    },
    search() {
      let input = this.$refs['input-search']

      if (input) {
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'catalog', query: {search: input.value}})
          if (this.$route.name === 'catalog') this.$router.go()
        }
      }
    },
    callModal(param) {
      if (param == 'marketplace') 
        this.$store.commit('setProductMarket', null)
      this.$store.commit('setModalNameEnabled', param)
    },
  },
}
</script>