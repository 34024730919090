<template>
  <div id="app">
    <!-- content -->
    <Header :isDevice="isDevice" />  
    <Router-view :isDevice="isDevice" /> 
    <Footer :isDevice="isDevice" />
    <!-- content -->

    <!-- modal forms -->
    <Marketplace  />
    <Feedback />
    <!-- modal forms -->
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Feedback from './components/feedback-form/FeedbackForm'
import Marketplace from './components/marketplace/Marketplace'

export default {
  name: 'App',
  components: {
    Feedback,
    Header,
    Footer,
    Marketplace
  },
  data() {
    return {
      feedback: false,
      marketplace: false,
      mp_product: null,
      mobileWidth: 540,
      tabletWidth: 920,
      laptopWidth: 1024,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    isMobile() {
        return this.windowWidth <= this.mobileWidth
    },
    isTablet() {
        return this.windowWidth <= this.tabletWidth && this.windowWidth > this.mobileWidth
    },
    isLaptop() {
        return this.windowWidth <= this.laptopWidth && this.windowWidth > this.tabletWidth
    },
    isDevice() {
        if (this.isMobile) return 'mobile'
        if (this.isTablet) return 'tablet'
        if (this.isLaptop) return 'laptop'
        return 'desktop'
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.getFooterHeight();
    },
    getFooterHeight() {
      const f_height = document.getElementById("footer").offsetHeight - 1;
      let App = document.getElementById("app");
      App.style.marginBottom = `${f_height}px`;
    }
  },
  mounted() {
    this.getFooterHeight();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>
