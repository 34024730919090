import { createApp } from './main'
import Vue from 'vue'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueAnalytics from 'vue-analytics'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueRellax from 'vue-rellax'
import moment from 'moment'
import 'uikit'
import 'uikit/dist/css/uikit.min.css'
import "./assets/style.sass"
import './assets/font/stylesheet.css'

Vue.prototype.$moment = moment
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` })
Vue.use(VueRellax)

const { app, router, store } = createApp();
const dbug = process.env.VUE_APP_ENV

if (process.env.VUE_APP_METRIC_YANDEX) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_METRIC_YANDEX,
    router: router,
    env: dbug
  })
}

if (process.env.VUE_APP_METRIC_GOOGLE) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_METRIC_GOOGLE,
    router: router,
    disabled: dbug !== 'production'
  })
}

Vue.directive('clickoutside', {
  inserted: (el, binding, vnode) => {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
});

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let func = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', func)
      }
    }
    window.addEventListener('scroll', func)
  }
});

router.onReady(() => {
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }

  app.$mount('#app');
});