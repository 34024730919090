import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ './views/OurPartners')
    },
    {
        path: '/store',
        name: 'store',
        component: () => import(/* webpackChunkName: "store" */ './views/StoreMap')
    },
    {
        path: '/company',
        name: 'company',
        component: () => import(/* webpackChunkName: "company" */ './views/AboutCompany.vue')
    },
    {
        path: '/product/:link',
        name: 'product',
        component: () => import(/* webpackChunkName: "product" */ './views/ProductPage')
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: () => import(/* webpackChunkName: "catalog" */ './views/Catalog')
    },
    {
        path: '/',
        name: 'main',
        component: () => import(/* webpackChunkName: "mainpage" */ './views/MainPage')
    },
    {
        path: '/articles/:link',
        name: 'aricle',
        component: () => import(/* webpackChunkName: "article" */ './views/ArticleItem')
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import(/* webpackChunkName: "articles" */ './views/ArticleItems')
    },
    {
       path: '*',
       name: 'not-found',
       component: () => import(/* webpackChunkName: "not-found" */ './views/NotFound')
    }
];


export function createRouter() { 
    return new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}