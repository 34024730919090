<template>
    <div id="footer" class="footer" :class="{ 'footer_focus': sfocus }">
      <div class="uk-container uk-container-large">
        <div class="footer__container">
          <div class="logo footer__logo">
            <a href="/">
              <KangarooLogo />
            </a>
          </div>

          <div class="footer__content">
            <div class="footer-menu">
              <div class="footer-menu__container main">
                <div
                  v-for="(item, i) in MainMenu"
                  :key="i"
                  class="footer-menu__item"
                  :class="{ 'active' : !$route.path.indexOf(item.href) }"
                >
                  <a
                    :href="item.href"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </div>

              <div class="footer-menu__container secondary">
                <div
                  v-for="(item, i) in SecondaryMenu"
                  :key="i"
                  class="footer-menu__item"
                  :class="{ 'active' : !$route.path.indexOf(item.href) }"
                >
                  <a
                    :href="item.href"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </div>
            </div>

            <div class="footer-search">
              <div class="footer-search__button">
                <button
                  @click="search($event)"
                  class="btn-search"
                  aria-label="Поиск"
                >
                  <div class="kng-i kng-i-search"></div>
                </button>
                <input
                  type="text"
                  ref="input-search"
                  class="input-search"
                  placeholder="Поиск на сайте"
                  v-model="textSearch"
                  @focus="sfocus = true"
                  @blur="sfocus = false"
                  @keypress.enter="search($event)"
                />
              </div>
            </div>
          </div>

          <div class="footer__content">
            <div class="footer-menu margin_right">
              <div class="footer-menu__container">
                <button 
                  @click="callModal('marketplace')"
                  class="button button-black"
                >
                  Купить на маркетплейсах
                </button>
              </div>

              <div class="footer-menu__container">
                <div class="footer-menu__info">
                  <p>
                    г. Владивосток, ул. Днепровская, 104
                  </p>
                  <a href="tel://84232790279">
                    8 (423) 279 02 79
                  </a>
                </div>

                <button class="button button-white" @click="callModal('feedback')">
                  Написать нам
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
import MenuJson from '@/assets/data/menu.json';
import KangarooLogo from '@/assets/img/svg/kangaroo_logo.svg';

export default {
  name: 'footer-component',
  components: {
    KangarooLogo
  },
  data() {
    return {
      MainMenu: MenuJson.filter(el => el.type === 1),
      SecondaryMenu: MenuJson.filter(el => el.type === 2),
      sfocus: false,
      textSearch: '',
    }
  },
  methods: {
    search() {
      let input = this.$refs['input-search']

      if (input) {
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'catalog', query: {search: input.value}})
          if (this.$route.name === 'catalog') this.$router.go()
        }
      }
    },
    callModal(param) {
      if (param == 'marketplace') 
        this.$store.commit('setProductMarket', null)
      this.$store.commit('setModalNameEnabled', param)
    },
  },
}
</script>