import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex)

const state = {
    modalNameEnabled: false,
    productMarket: null,
    headerTheme: 'default',
    headerPosition: 'absolute',
    headerTop: 0,
    categories: [],
    app_area: [],
    selected_category: null,
    selected_area: null
}

const getters = {
    getModalNameEnabled(state) {
        return state.modalNameEnabled
    },
    getProductMarket(state) {
        return state.productMarket
    }
}

const mutations = {
    setModalNameEnabled(state, payload) {
        state.modalNameEnabled = payload
    },
    setProductMarket(state, payload) {
        state.productMarket = payload
    },
    setHeaderTheme(state, payload) {
        state.headerTheme = payload
    },
    setHeaderPosition(state, payload) {
        state.headerPosition = payload
    },
    setHeaderTop(state, payload) {
        state.headerTop = payload
    },
    setCategories(state, payload) {
        state.categories = payload
    },
    setAppArea(state, payload) {
        state.app_area = payload
    },
    selectCategory(state, payload) {
        state.selected_category = payload
    },
    selectAppArea(state, payload) {
        state.selected_area = payload
    }
}

export function createStore() {
    return new Vuex.Store({
        state,
        getters,
        mutations,
    })
}

