<template>
  <div class="marketplace" :class="{ marketplace_open : marketEnabled }">
    <div @click="clickOutside" class="marketplace__fixed">
      <div class="marketplace__modal uk-container uk-container-small">
        <div class="marketplace__container">
          <div class="marketplace__content">
            <div class="marketplace__close" @click="hideForm">
              <div class="kng-i kng-i-close"></div>
            </div>
            <div class="marketplace__title">
              Выберите маркетплейс
            </div>
            <div class="marketplace__choose">
              <a :href="links.ozon" target="_blank">
                <button class="button button-market button-ozon" aria-label="Ozon">
                  <Ozon />
                </button>
              </a>
              <a :href="links.wildberries" target="_blank">
                <button class="button button-market button-wb" aria-label="Wildberries">
                  <WildBerries />
                </button>
              </a>

              <a :href="links.yanmarket" target="_blank">
                <button class="button button-market button-yanmarket" aria-label="Yanmarket">
                  <YanMarket />
                  <!-- <img src="../../assets/img/svg/icon-yanmarket.png" alt="icon-yamarket.png" class= "icon-yanmarket"> -->
                </button>
              </a>

           

              <a :href="links.sber" target="_blank">
                <button class="button button-market button-sber" aria-label="Sber">
                  <!-- <Sber /> -->
                   <img src="../../assets/img/svg/SBER_2.png" alt="icon-yamarket.png" class= "icon-yanmarket">
                </button>
              </a>

             


            </div>
          </div>
        </div>
       </div>
    </div>
  </div>
</template>

<script>
import Ozon from '@/assets/img/svg/ozon.svg';
import WildBerries from '@/assets/img/svg/wildberries.svg';
import YanMarket from '@/assets/img/svg/yamarket.svg';
//import Sber from '@/assets/img/svg/sber.svg';

export default {
  name: 'marketplace-component',
  components: {
    Ozon,
    WildBerries,
    YanMarket,
    //Sber
  },
  computed: {
    marketEnabled() {
      return this.$store.state.modalNameEnabled == "marketplace"
    },
    links() {
      const product = this.$store.state.productMarket
      
      let ozon = process.env.VUE_APP_LINK_OZON
      let wildberries = process.env.VUE_APP_LINK_WILDBERRIES  
      let yanmarket = process.env.VUE_APP_LINK_YANDEXMARKET  
      let sber = process.env.VUE_APP_LINK_SBER 
      let text = product?.replace(/[ ()$&+]/g, "+") ?? 'kangaroo'

      ozon = `${ozon}/brand/kangaroo-30955406/?opened=seller&seller=80642%2C127933`;
      wildberries = `${wildberries}/catalog/0/search.aspx?page=1&sort=popular&fbrand=21677&fsupplier=38336&search=${encodeURI(text)}`;
      yanmarket = `${yanmarket}/catalog--avtokhimiia-i-avtokosmetika/54449/list?hid=90428&rs=eJwdUD...cq6XJVz7hWRXfMUjVtmWZGt3VdMzTjH5HOqMI%2C&businessId=886432&glfilter=7893318%3A14279675`;
      sber = `${sber}/shop/gk-yunikom/catalog/avtokosmetika-i-avtohimiya/#?filters=%7B"2B0B1FF4756D49CF84B094522D57ED3D"%3A%5B"Kangaroo"%5D%7D`;
      
      return {
        ozon,
        wildberries,
        yanmarket,
        sber
      }
    }
  },
  watch: {
    marketEnabled(val) {
      let body = document.getElementById("app").parentElement;
      if (val) body.classList.add('modal-open');
      else body.classList.remove('modal-open');
    }
  },
  methods: {
    clickOutside(event) {
      if (!event.target.closest('.marketplace__modal')) {
        this.hideForm()
      }
    },
    hideForm() {
      if (this.marketEnabled === true) {
        this.$store.commit('setModalNameEnabled', false)
      }
    },
  },
}
</script>